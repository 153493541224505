var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", [_vm._v("Agents Stats")]),
      _c("b-table", {
        attrs: {
          items: _vm.updated_data,
          fields: _vm.fields,
          hover: "",
          striped: "",
          dark: ""
        },
        scopedSlots: _vm._u([
          {
            key: "cell(agent)",
            fn: function(field) {
              return [
                _c("div", { staticClass: "d-flex align-items-end" }, [
                  _c("img", {
                    attrs: {
                      src: field.item.agent.icon,
                      alt: field.item.agent.name
                    }
                  }),
                  _c("h5", { staticClass: "ml-2" }, [
                    _vm._v(" " + _vm._s(field.item.agent.name) + " ")
                  ])
                ])
              ]
            }
          },
          {
            key: "cell(wins)",
            fn: function(field) {
              return [
                _c("div", [
                  _c("strong", [_vm._v(_vm._s(field.item.wins))]),
                  _c("span", { staticClass: "text-muted small ml-1" }, [
                    _vm._v(_vm._s(field.item.wins_percentage) + "%")
                  ])
                ])
              ]
            }
          },
          {
            key: "cell(loses)",
            fn: function(field) {
              return [
                _c("div", [
                  _c("strong", [_vm._v(_vm._s(field.item.loses))]),
                  _c("span", { staticClass: "text-muted small ml-1" }, [
                    _vm._v(_vm._s(field.item.loses_percentage) + "%")
                  ])
                ])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }