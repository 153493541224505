var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.loading_stats
      ? _c(
          "div",
          { staticClass: "flex-fill d-flex justify-content-center mt-4 mb-4" },
          [_vm._v("Loading...")]
        )
      : _c(
          "div",
          [
            _c("TableGeneralStats", {
              staticClass: "mb-5",
              attrs: { data: _vm.general_stats }
            }),
            _c("TableAgentsStats", {
              staticClass: "mb-5",
              attrs: { data: _vm.agents_stats }
            }),
            _c("TableAimStats", {
              staticClass: "mb-5",
              attrs: { data: _vm.aim_stats }
            }),
            _c("TablePerRoleStats", {
              staticClass: "mb-5",
              attrs: { data: _vm.per_role_stats }
            }),
            _c("TableWeaponStats", {
              staticClass: "mb-5",
              attrs: { data: _vm.weapon_stats, show_pick_percentage: true }
            }),
            _c("TableClutches", {
              staticClass: "mb-5",
              attrs: { data: _vm.clutches }
            })
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }