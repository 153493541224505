var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", [_vm._v("Per Role Stats")]),
      _c("b-table", {
        attrs: {
          items: _vm.updated_data,
          fields: _vm.fields,
          hover: "",
          striped: "",
          dark: ""
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }