var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", [_vm._v("Aim Stats")]),
      _c("b-table", {
        attrs: {
          items: _vm.updated_data,
          fields: _vm.fields,
          hover: "",
          striped: "",
          dark: ""
        },
        scopedSlots: _vm._u([
          {
            key: "cell(count)",
            fn: function(field) {
              return [
                _c("div", [
                  _c("strong", [_vm._v(_vm._s(field.item.count))]),
                  _c("span", { staticClass: "text-muted small ml-1" }, [
                    _vm._v(_vm._s(field.item.stat_percentage) + "%")
                  ])
                ])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }